<template>
    <div>
        <div class="waterfallWrap">
            <CheckboxGroup class="waterfall grid" v-model="selectedGroup" @on-change="checkAllGroupChange">
                <div class="item grid-item" v-for="(item, index) in list" :key="item.rowid">
                    <div class="img-wrap">
                        <img :src="'/cym/'+item.uri+'/thumbnail'" :class="['image-center', item.material_type.material_type==='template'||item.material_type.material_type==='data_template'?'template-preview-image-bkg':'']" >
                        <div v-if="checkAllGroup.length === 0" class="img-mask" @click="handlePreview(item, index)"></div>
                        <Checkbox v-if="allow"  :class="['checkbox', selectedGroup.length === 0 ? '' : 'checked']" :label="item.rowid" ><span>&nbsp;</span></Checkbox>
                        <span class="type" key="1">{{ item.material_type.text[lang]?item.material_type.text[lang]:item.material_type.text._ }}</span>
                        <slot name="action-btn" :index="index" :item="item" ></slot>
                        <slot name="dropdown" :index="index" :item="item" ></slot>
                    </div>
                    <div class="name-wrap pointer" @click="showElement($event, item.rowid)">
                        <div class="name line-overflow" >
                            {{ item.name }}
                        </div>
                        <Icon class="icon" type="md-create" />
                    </div>
                </div>
            </CheckboxGroup>
            <Spin fix size="large" v-if="loading"></Spin>
        </div>
        <Page
            class="page"
            :total="total"
            :current="current"
            :page-size="pageSize"
            show-elevator
            @on-change="nextPage" />
    </div>  
</template>
<script>
    import Masonry from "masonry-layout";
    // import imagesloaded from 'imagesloaded';
    import { PreviewImage } from 'components/preview.js'
    // , PreviewVideo, PreviewAudio
    import { rename } from '@/mixins/index'
    import util from '@/libs/util.js'


    export default {
        name: "",
        mixins: [rename],
        components: {},
        props: {
            from: {
                default: 'person'
            },
            total: {
                default: 0
            },
            current: {
                default: 1
            },
            pageSize: {
                default: 10
            },
            list: {
                default: ()=>{
                    return []
                }
            },
            loading: {
                default: false
            },
            checkAllGroup: {
                default: ()=>{
                    return []
                }
            },
            lang: {
                default: ''
            },
            handleCopyToCurrent: Function,
            handleEditTmpl: Function
        },
        data () {
            return {
                options: {
                    itemSelector: ".grid-item",
                    columnWidth: ".grid-item",
                    initLayout: false,
                    gutter: 20,
                    resize: true,
                    percentPosition: true,
                },
                selectedGroup: []
            }
        },
        computed: {
            allow() {
                if (this.from === 'person' || (this.from === 'company' && this.$store.state.rolesList.indexOf('content') > -1)) {
                    return true
                } else {
                    return false
                }
            }
        },
        watch: {
            list: {
                handler() {
                    this.$nextTick(() => {
                        if (!this.msnry) {
                            this.initMsnry()
                        } else {
                            this.msnry.reloadItems()
                            // imagesloaded('.grid-item', () => {
                            //     this.msnry.layout(); // 图片加载完成后重新绘制。
                            // });
                            this.msnry.layout();
                        }
                    })
                },
                deep: true,
                // immediate: true
            },
            checkAllGroup(val) {
                this.selectedGroup = val
            }
        },
        methods: {
            nextPage(e) {
                this.$emit('nextPage', e)
            },
            initMsnry() {
                var grid = document.querySelector(".grid");
                this.msnry = new Masonry(grid, this.options);

                // imagesloaded('.grid-item', () => {
                //     this.msnry.layout(); // 图片加载完成后重新绘制。
                // });
                this.msnry.layout();
            },
            handleRename(name, rowid) {
                this.$emit('rename', { rowid, name })
            },
            handleRemove(rowid, bucket_token) {
                return this.$api.delete(`materials/${rowid}?bucket_token=${bucket_token}`).then(()=>{
                    this.$Message.success(this.$t('common.success'));
                    this.$emit('refresh')
                })
            },
            handleSelect(rowid, value) {
                this.$emit('handleSelect', { rowid, value })
            },
            handleRefreshLabel() {
                this.$emit('handleRefreshLabel')
            },
            async handlePreview(item, index) {
                let available = true
                if (item.material_type.material_type === 'video') {
                    await this.$api.get(`${item.uri}/code_status`).then(({ can_preview, message }) => {
                        available = can_preview
                        if (!can_preview) {
                            this.$Message.info(message)
                        }
                    }).catch(() => {
                        available = false
                    })
                }
                if (!available) return
                PreviewImage({
                    from: this.from,
                    sources: this.handleResources(this.list),
                    index: index,
                    handleParentRename: this.handleRename,
                    handleRemove: this.handleRemove,
                    handleRefreshLabel: this.handleRefreshLabel,
                    handleUseTmpl: this.handelEditTmpl,
                    handleCopyTmpl: this.handleCopyTmpl,
                    handleEditTmpl: this.handleEditTmpl
                })
            },
            handleResources(list) {
                let resources = list.map((item) => {
                    let type = item.material_info.media_type
                    let params = {
                            src: `/cym/${item.uri}/preview`,
                            add_to_plan_flag: item.add_to_plan_flag,
                            create_time: util.handleTime(item.create_time),
                            name: item.name,
                            rowid: item.rowid,
                            bucket_token: item.bucket_token,
                            format_name: item.material_info.format_name,
                            type_text: item.material_type.text[this.lang]?item.material_type.text[this.lang]:item.material_type.text._,
                            material_type: item.material_info.media_type,
                            allow: this.allow,
                            target_size: item.material_info.target_size,
                            category: item.material_info.category,
                            material_type2:  item.material_type.material_type
                        },
                        size = item.bytes_size/(1024*1024);
                        if (size >= 1) {
                            params.bytes_size = Math.round(size*10)/10 + 'M'
                        } else if (size >= 0 && size < 1) {
                            params.bytes_size =  Math.round(10*item.bytes_size/1024)/10 + 'KB'
                        }
                    if (type === 'image' || type === 'animation' || type === 'template' || type === 'data_template') {
                        params.type = 'image'
                        params.resolution = `${item.material_info.resolution[0]}X${item.material_info.resolution[1]}PX`

                    } else if (type === 'video') {
                        params.type = 'video'
                        params.resolution = `${item.material_info.resolution[0]}X${item.material_info.resolution[1]}PX`
                    } else {
                        params.type = 'audio'
                    }
                    return params
                })
                return resources
            },
            handleCopyTmpl(list, that) {
                return this.handleCopyToCurrent(list).then((data=[]) => {
                    that.sources = this.handleResources(data)
                    that.index = 0
                    that.swiper.slideTo(that.index, 0)
                })
            },
            handelEditTmpl(rowid) {
                const { href } = this.$router.resolve({
                    name: "templateDesign",
                    query: {
                        state: 'edit',
                        rowid: rowid
                    }
                });
                window.open(href, '_blank')
            },
            checkAllGroupChange(data) {
                this.$emit('checkAllGroupChange', data)
            }
        },
        beforeDestroy() {
            this.msnry && this.msnry.destroy();
        },
        updated() {},
        created() {},
        mounted() {}
    }
</script>
<style  lang="less">
@import "~less/color.less";
.waterfallWrap {
    position: relative;
    .waterfall {
        width: calc(100% + 20px);
        .item {
            position: relative;
            margin: 0 0px 20px 0;
            width: calc(16.7% - 20px);
            .img-wrap {
                position: relative;
                width: 100%;
                padding-top: 116.8%;
                background: #cfcfcf;
                box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
                &:hover {
                    .checkbox, .play, .edit, .more {
                        display: block;
                    }
                    .img-mask {
                        opacity: 1;
                    }
                }
                .img-mask {
                    cursor: zoom-in;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.08);
                    transition: opacity .3s ease;
                }
                .checkbox, .play, .edit, .more {
                    display: none;
                }
                .checkbox {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-right: 0;
                    padding: 5px;
                }
                .checked {
                    display: inline-block;
                    width: 100%;
                    height: 100%;

                }
                .type {
                    position: absolute;
                    right: 12px;
                    top: 16px;
                    height: 16px;
                    padding: 0 5px;
                    border-radius: 4px;
                    background: #f8fbff;
                    font-size: 12px;
                    text-align: center;
                    line-height: 16px;
                }
                .play {
                    position: absolute;
                    left: 5.6%;
                    bottom: 20px;
                    width: 40%;
                    height: 30px;
                    background: rgba(0,0,0,0.4);
                    border-radius: 2px;
                    font-size: 12px;
                    line-height: 30px;
                    color: @background-color;
                    text-align: center;
                    &:hover {
                        background: rgba(0,0,0,0.6);
                    }
                }
                .play2 {
                    width: 90px;
                    font-size: 14px;
                }
                .edit {
                    position: absolute;
                    left: 50%;
                    width: 24%;
                    bottom: 20px;
                    height: 30px;
                    background: rgba(0,0,0,0.4);
                    border-radius: 2px;
                    font-size: 12px;
                    line-height: 30px;
                    color: @background-color;
                    text-align: center;
                    &:hover {
                        background: rgba(0,0,0,0.6);
                    }
                }
                .more {
                    position: absolute;
                    left: 77%;
                    bottom: 20px;
                    .md-more {
                        position: relative;
                        width: 30px;
                        height: 30px;
                        background: rgba(0,0,0,0.4);
                        border-radius: 50%;
                        &:hover {
                            background: rgba(0,0,0,0.6);
                        }
                        .icon {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: #f8fbff;
                        }
                    }
                }
            }
            .name-wrap {
                margin-top: 5px;
                .name {
                    display: inline-block;
                    position: relative;
                    max-width: calc(100% - 24px);
                    line-height: 21px;
                    vertical-align: middle;
                }
                .icon {
                    display: none;
                    vertical-align: middle;
                }
                &:hover {
                    .icon {
                        display: inline-block;
                    }
                }

            }
        }
        @media screen and (min-width: 1600px) {
            .item {
                .img-wrap {
                    .play, .edit {
                        height: 35px;
                        font-size: 15px;
                        line-height: 35px;
                    }
                    .play2 {
                        width: 120px;
                    }
                    .more {
                        .md-more {
                            width: 35px;
                            height: 35px;
                        }
                    }
                }
            }
        }
        @media screen and (min-width: 1800px) {
            .item {
                .img-wrap {
                    .play, .edit{
                        height: 40px;
                        font-size: 16px;
                        line-height: 40px;
                    }
                    .paly2 {
                        width: 140px;
                    }
                    .more {
                        .md-more {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}
</style>