<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="type==='add'?$t('pages.label.add'):$t('common.rename')"
        :mask-closable="false"
        width="400"
        :transfer="false">
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" @submit.native.prevent>
            <FormItem label="" prop="name">
                <Input v-model.trim="formValidate.name" size="large" :placeholder="$t('pages.label.inputName')"></Input>
            </FormItem>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            bucket_token: {
                default: null
            },
            type: {
                default: 'add'
            },
            editInfo: {
                default: () => {
                    return {}
                }
            },
            label_type: {
                default: 'material'
            }
        },
        data () {
            return {
                modal: false,
                formValidate: {
                    name: ''
                },
                loading: false
            }
        },
        computed: {
            ruleValidate() {
                return {
                    name: [
                        { required: true, message: this.$t('pages.label.inputName'), trigger: 'blur' }
                    ]
                }
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (!val) return
                if (this.type === 'edit') {
                    this.formValidate.name = this.editInfo.name
                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.formValidate.name = ''
                this.$emit('cancel')
                this.$refs['formValidate'].resetFields();
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        let maxLen = 32
                        if (this.label_type === 'commodity') maxLen = 8
                        if (this.formValidate.name.length > maxLen) {
                            return this.$Message.error(`${this.$t('pages.label.limit')}${maxLen}${this.$t('pages .label.unit')}${maxLen>1?this.$t('pages.label.characters'):this.$t('pages.label.character')}`)
                        }
                        this.loading = true
                        let params = {
                                name: this.formValidate.name,
                                bucket_token: this.bucket_token,
                                label_type: this.label_type
                            },
                            url = '';
                        if (this.type === 'add') {
                            url = 'labels'
                            this.$api.post(url, params).then(() => {
                                this.$Message.success(this.$t('message.success'));
                                this.$emit('confirm')
                                this.cancel()
                            }).finally(() => {
                                this.loading = false
                            })
                        } else {
                            url = `labels/${this.editInfo.rowid}`
                            this.$api.put(url, params).then(() => {
                                this.$Message.success(this.$t('message.success'));
                                this.$emit('confirm')
                                this.cancel()
                            }).finally(() => {
                                this.loading = false
                            })
                        }
                    }
                })
            }
        },
        created() {}
    }
</script>
<style scoped>
    
</style>