<template>
    <div id="person-spaces" class="content">
        <div class="header">
            <Row>
                <Col span="6">
                    <h1 class="title">{{ $t('navigation.personSpaces') }}
                        ({{ total }})
                    </h1>
                </Col>
                <Col span="18" class="clearfix">
                    <Button class="float-right" type="primary" @click="handleLabelMag">{{ $t('pages.label.labelMag') }}</Button>
                    <Button class="float-right margin-right" type="primary" @click="handleCreateTmpl">{{ $t('pages.space.createTemplate') }}</Button>
                    <Button class="float-right margin-right" type="primary" @click="handleUpload">{{ $t('pages.space.uploadMaterials') }}</Button>
                    <Input v-model="query" class="float-right margin-right" clearable :placeholder="$t('common.searchLibrary')" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                        <Icon class="pointer" type="ios-search" slot="suffix" @click="searchNew" />
                    </Input>
                </Col>
            </Row>
        </div>
        <div class="main-content">
            <Row class="action-bar">
                <Col span="18">
                    <div v-if="checkAllGroup.length === 0">
                        <RadioGroup class="radio-group-button" v-model="type" type="button" @on-change="handleTypeList">
                            <Radio label="all">{{ $t('common.all') }}</Radio>
                            <Radio v-for="item in typeList" :label="item.material_type" :key="item.material_type">{{ item.text[lang]?item.text[lang]:item.text._ }}</Radio>
                        </RadioGroup>
                        <Dropdown class="dropdown dropdown-label" trigger="click" placement="bottom-start" >
                            <Icon v-if="label" class="close" type="ios-close-circle" @click="clearLabelList" />
                            <Button type="text">
                                {{ $t('pages.label.label') }}<span v-if="label">:{{ label.name }}</span>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <load-more v-if="labelList.length !== 0"  :onRefresh="handleReachBottom" :disabled="isLoaded" height="165px">
                                    <Tooltip class="tooltip" :content="item.name" theme="light" placement="top-start" transfer v-for="(item, index) in labelList" :key="index" :delay="1000">
                                        <DropdownItem class="label line-overflow" @click.native="handleLabelList(item)">{{ item.name }}</DropdownItem>
                                    </Tooltip>
                                </load-more>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div v-else>

                        <Button v-if="dist_bucket_token" class="margin-right" type="primary" @click="handleCopy(checkAllGroup)">{{ $t('pages.space.copyTo') }}</Button>
                        <Button class="margin-right" type="primary" @click="handelLabel(checkAllGroup)">{{ $t('pages.label.editLabel') }}</Button>
                        <Button type="primary" @click="handleRemove(checkAllGroup)">{{ $t('common.del') }}</Button>
                    </div>
                </Col>
                <Col span="6">
                    <Checkbox
                        v-show="list.length !== 0"
                        class="float-right all"
                        :indeterminate="indeterminate"
                        :value="checkAll"
                        @click.prevent.native="handleCheckAll">
                        <span v-if="checkAllGroup.length === 0">{{ $t('common.selectAll') }}</span>
                        <span v-else>{{ $t('common.selected') }}{{ checkAllGroup.length }}{{ $t('pages.space.unit') }}{{ $t(checkAllGroup.length>1?'pages.space.contents':'pages.space.content') }}</span>
                    </Checkbox>
                </Col>
            </Row>
            <no-data v-show="list.length === 0" :text="$t('pages.space.noMaterials')"></no-data>
            <spaces-common
                v-show="list.length > 0"
                ref="spaces"
                :total="total"
                :current="current"
                :pageSize="pageSize"
                :list="list"
                :loading="loading"
                :checkAllGroup="checkAllGroup"
                :handleCopyToCurrent="handleCopyToCurrent"
                :handleEditTmpl="handleEditTmpl"
                :lang="lang"
                @nextPage="nextPage"
                @rename="submitRename"
                @checkAllGroupChange="checkAllGroupChange"
                @refresh="getData"
                @handleRefreshLabel="handleRefreshLabel"
            >
                <template  slot="action-btn" slot-scope="{ index, item }" >
                    <div v-if="item.add_to_plan_flag && checkAllGroup.length === 0 && !dist_bucket_token" :class="[item.material_type.material_type==='template' || item.material_type.material_type==='data_template'?'play':'play play2', 'pointer']" @click="handlePaly(item)" >{{ $t('common.post') }}</div>
                    <div v-if="(item.material_type.material_type==='template' || item.material_type.material_type==='data_template') && checkAllGroup.length === 0" :class="[item.add_to_plan_flag&&!dist_bucket_token?'edit':'play play2', 'pointer']" @click="handleEditTmpl(item.rowid, item.material_type.material_type)">{{ $t('common.edit') }}</div>                </template>
                <Dropdown v-if="checkAllGroup.length === 0" class="more" placement="bottom-start" trigger="click" slot="dropdown" slot-scope="{ index, item }" >
                    <a href="javascript:void(0)">
                        <div class="md-more"><Icon class="icon" type="md-more" size="20" /></div>
                    </a>
                    <DropdownMenu slot="list" >
                        <DropdownItem v-if="dist_bucket_token" @click.native="handleCopy([item.rowid])">{{ $t('pages.space.copyTo') }}</DropdownItem>
                        <DropdownItem v-if="item.material_type.material_type==='template' || item.material_type.material_type==='data_template'" @click.native="handelCopyToCurrent([item.rowid])">{{ $t('common.createACopy') }}</DropdownItem>
                        <DropdownItem @click.native="handelLabel([item.rowid])">{{ $t('pages.label.editLabel') }}</DropdownItem>
                        <DropdownItem @click.native="handleRename(index)">{{ $t('common.rename') }}</DropdownItem>
                        <DropdownItem @click.native="handleRemove([item.rowid])">{{ $t('common.del') }}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </spaces-common>
        </div>
        <edit-label
            :show="labelModalShow"
            :bucket_token="bucket_token"
            :material_ids="material_ids"
            @confirm="handleLabelConfirm"
            @cancel="handleLabelCancel"
            @refresh="handleRefresh"
        ></edit-label>
        <uploadModal
            ref="upload"
            :show="uploadModalShow"
            :bucket_token="bucket_token"
            @confirm="handleUploadConfirm"
            @cancel="handleUploadCancel"
            @show="handleUploadModal"
            @refresh="handleRefreshLabel"
        ></uploadModal>
        <playSetting
            :show="playModalShow"
            :materialInfo="materialInfo"
            @confirm="handlePlayConfirm"
            @cancel="handlePlayCancel"
        >
            <div class="material-block" slot="material" :slot-scope="{ materialInfo }">
                <div class="img-wrap">
                    <img v-if="materialInfo.uri" :class="['image-center', materialInfo.material_type==='template'||materialInfo.material_type==='data_template'?'template-preview-image-bkg':'']" :src="`${materialInfo.uri}/thumbnail?size=[295,-1]`">
                </div>
                <div class="info">
                    <p>{{ $t('pages.space.materialName') }}：{{ materialInfo.name }}</p>
                    <p>{{ $t('pages.space.resolutionRatio') }}：{{ materialInfo.resolution }}</p>
                    <p v-if="materialInfo.bytes_size">{{ $t('pages.space.materialSize') }}：{{ materialInfo.bytes_size }}</p>
                </div>
            </div>
        </playSetting>
        <label-mag-modal
            :show="labelMagModalShow"
            :bucket_token="bucket_token"
            @cancel="handleLabelMagCancel"
            @refresh="handleRefreshLabel"
            @clear="clearLabelList"
        ></label-mag-modal>
        <add-modal
            :show="addModalShow"
            :department_id="department_id"
            :title="$t('pages.screen.addScreenTip')"
            @cancel="handleAddCancel"
        ></add-modal>
        <loading-modal
            :show="loadingModalShow"
            :percent="percent"
            @cancel="handleLoadingModalCancel"
        ></loading-modal>
        <failed-modal
            :show="failedModalShow"
            :list="block_list"
            @cancel="hideFailedModal"
        ></failed-modal>
    </div>
</template>
<script>
    import editLabel from './components/editLabel'
    import spacesCommon from './components/spacesCommon'
    import uploadModal from 'views/components/upload'
    import playSetting from 'views/components/playSetting'
    import labelMagModal from './components/labelMag'
    import noData from '@/views/components/noData'
    import loadMore from '@/views/components/loadMore'
    import addModal from '@/views/screens/mediaScreens/components/add'
    import loadingModal from '@/views/components/loadingModal'
    import failedModal from '@/views/components/failedModal'
    import { checkbox } from '@/mixins/index'
    import checkUploadStatus from '@/mixins/checkUploadStatus'

    export default {
        name: "",
        mixins: [checkbox, checkUploadStatus],
        components: {
            editLabel,
            uploadModal,
            spacesCommon,
            playSetting,
            labelMagModal,
            noData,
            loadMore,
            addModal,
            loadingModal,
            failedModal
        },
        props: [],
        data () {
            return {
                total: 0,
                current: 1,
                pageSize: 36,
                labelModalShow: false,
                uploadModalShow: false,
                playModalShow: false,
                query: '',
                list: [],
                loading: false,
                indeterminate: false,
                checkAll: false,
                checkAllGroup: [],
                //素材分类
                materialClass: "all",
                //素材类型详情
                typeList: [],
                //模版类型
                templateTypeList: [],
                type: 'all',
                materialInfo: {},
                label: null,
                labelList: [],
                labelMagModalShow: false,
                material_ids: [],
                labelPageSize: 10,
                labelCurrent: 1,
                isLoaded: false,
                addModalShow: false,
            }
        },
        computed: {
            bucket_token() {
                return this.$store.state.default_bucket_tokens.user
            },
            dist_bucket_token() {
                return this.$store.state.default_bucket_tokens.org
            },
            department_id() {
                return this.$store.state.user_tree.department_id
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            },
            lang() {
                return this.$store.state.lang
            }
        },
        methods: {
            searchNew() {
                if (this.loading == true) return
                this.current = 1
                this.getData()
            },
            handleRemove(list) {
                this.$Modal.confirm({
                    title: this.$t('common.delTip'),
                    content: this.$t('common.delCon'),
                    okText: this.$t('common.confirmDel'),
                    cancelText: this.$t('common.cancel'),
                    loading: true,
                    closable: true,
                    onOk: () => {
                        let newList = [...list],
                            len = newList.length,
                            str = ''
                        for (let i = 0; i < len; i++) {
                            str += newList[i]
                            if (i !== len - 1) {
                                str += ','
                            }
                        }
                        this.$api.delete(`materials/${str}?bucket_token=${this.bucket_token}`).then(()=>{
                            this.$Message.success(this.$t('message.success'));
                            this.checkAllGroup = []
                            this.indeterminate = false
                            this.checkAll = false
                            let len = list.length;
                            if(this.total - len === (this.current - 1) * this.pageSize && this.current > 1) {
                                this.current -= 1
                            }
                            this.getData()
                        }).finally(() => {
                            this.$Modal.remove()
                        })

                    }
                });
            },
            handleCopyToCurrent(list) {
                return this.$api.post('materials/copy', { rowid_list: list, dist_bucket_token: this.bucket_token }).then(() => {
                    this.checkAllGroup = []
                    this.indeterminate = false
                    this.checkAll = false
                    return this.getData()
                }).catch(() => {})
            },
            handleAddCancel() {
                this.addModalShow = false
            },
            handelLabel(material_ids) {
                this.labelModalShow = true
                this.material_ids = material_ids
            },
            handleRefresh() {
                this.getData()
                this.handleRefreshLabel()
            },
            handleRefreshLabel() {
                this.labelCurrent = 1
                this.labelList =  []
                this.getLabelList()
            },
            handleLabelConfirm() {
                this.handleLabelCancel()
                this.checkAllGroup = []
                this.indeterminate = false
                this.checkAll = false
            },
            handleLabelCancel() {
                this.labelModalShow = false
            },
            handleUpload() {
                if (this.$store.state.rolesList.length === 0) return this.addModalShow = true
                if (this.$refs['upload'].defaultList.length > 0) {
                    this.$refs['upload'].loadingModalShow = true
                    return
                }
                this.$el.querySelector('.upload').getElementsByTagName('input')[0].click()
                this.$refs['upload'].init = true
            },
            handleUploadModal() {
                this.uploadModalShow = true
            },
            handleUploadConfirm() {
                this.handleUploadCancel()
                this.current = 1
                this.getData()
            },
            handleUploadCancel() {
                this.uploadModalShow = false
            },
            handleRename(index) {
                var eles = document.querySelectorAll('.name')
                eles[index].click()
            },
            submitRename({ rowid, name }) {
                let params = {
                    name: name,
                    bucket_token: this.bucket_token
                }
                this.$api.put(`materials/${rowid}`, params).then(()=>{
                    this.$Message.success('成功')
                    this.getData()
                })
            },
            handleEditTmpl(rowid, templateType) {
                let params = {
                        state: 'edit',
                        rowid: rowid,
                        templateType: templateType,
                        spaceType: 'person'
                    }
                if (this.is_org) params.palyable = false
                const { href } = this.$router.resolve({
                    name: "templateDesign",
                    query: { ...params }
                });
                window.open(href, '_blank')
            },
            handleCopy(list) {
                let newList = [...list],
                    len = newList.length,
                    str = '';
                for (let i = 0; i < len; i++) {
                    str += newList[i]
                    if (i !== len - 1) {
                        str += ','
                    }
                }
                this.$api.post(`materials/copy?rowid_list=${str}&&dist_bucket_token=${this.dist_bucket_token}`).then(({ pass_list, result_rowid_dict={} })=>{
                    if (pass_list && pass_list.length) {
                        this.switch = false
                    } else {
                        this.switch = true
                    }
                    if (this.switch) {
                        let result_list = []
                        for (let v in result_rowid_dict) {
                            result_list.push(result_rowid_dict[v])
                        }
                        this.loadingModalShow = true
                        const params = {
                            bucket_token: this.dist_bucket_token,
                            rowid_list: JSON.stringify(result_list)
                        }
                        this.timer = setInterval(this.check_upload_status(params), 1000)
                    } else {
                        this.$Message.success(this.$t('message.success'))
                    }
                    this.checkAllGroup = []
                    this.indeterminate = false
                    this.checkAll = false
                    this.getData()
                })
            },
            handlePaly(item) {
                let { rowid, name, uri, material_info } = item,
                    size = item.bytes_size/(1024*1024);

                this.materialInfo = {
                    type: 'material',
                    rowid: rowid,
                    name: name,
                    uri: `/cym/${uri}`,
                    resolution: `${material_info.resolution[0]}x${material_info.resolution[1]}PX`,
                    material_type: item.material_type.material_type,
                    category: material_info.category?material_info.category:'',
                    target_size: material_info.target_size
                }
                if (item.material_type.material_type !== 'template' && item.material_type.material_type !== 'data_template') {
                    if (size >= 1) {
                        this.materialInfo.bytes_size = Math.round(size*10)/10 + 'M'
                    } else if (size >= 0 && size < 1) {
                        this.materialInfo.bytes_size =  Math.round(10*item.bytes_size/1024)/10 + 'KB'
                    }
                }
                this.playModalShow = true
            },
            handlePlayConfirm() {
                this.getLabelList()
                this.handlePlayCancel()
            },
            handlePlayCancel() {
                this.playModalShow = false
            },
            getData() {
                let params = {
                    bucket_token: this.bucket_token,
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }
                if (this.type !== 'all') {
                    params.type_list = this.type
                }

                if (this.label) {
                    params.label_id = this.label.rowid
                }
                this.loading = true
                this.checkAll = false
                this.indeterminate = false

                return this.$api.get('materials/search', { params }).then(({ data, total_count, page })=>{
                    if (page === this.current) {
                        let isAll = true
                        //检测是否当页全选，当前页全勾选，显示全选状态
                       data.map((item)=>{
                            if (this.checkAllGroup.indexOf(item.rowid)== -1) {
                                isAll = false
                            } else {
                                this.indeterminate = true
                            }
                        })
                        if (isAll) {
                            this.indeterminate = false;
                            this.checkAll = true;
                        }

                        this.list = data
                        this.total = total_count
                        this.loading = false
                        return data
                    }
                }).catch(()=>{
                    this.loading = false
                }).finally(()=>{})
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            async getTypeList(e) {
                let url = '';
                this.type = 'all'
                switch(e) {
                    case 'all':
                        url = 'materials/type_list';
                        break;
                    case 'templates':
                        return;
                    case 'materials':
                        url = 'materials/media_type_list';
                        break;
                }
                await this.$api.get(url).then(({ data }) => {
                    this.typeList = data
                })
                this.getData()
            },
            handleTypeList() {
                this.current = 1
                this.getData()
            },
            getTemplateTypeList() {
                this.$api.get('materials/template_type_list').then(({ data }) => {
                    this.templateTypeList = data
                })
            },
            handleTemplateType(typeList) {
                this.typeList = typeList
            },
            getLabelList() {
                let params = {
                    bucket_token: this.bucket_token,
                    start: (this.labelCurrent - 1) * this.labelPageSize,
                    count: this.labelPageSize
                }
                return this.$api.get('labels/list', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.labelCurrent += 1
                        this.labelList.push(...data)
                    }
                    if (this.labelList.length == total_count) {
                        this.isLoaded = true
                    } else {
                        this.isLoaded = false
                    }
                })
            },
            clearLabelList() {
                this.current = 1
                this.label = ''
                this.getData()
            },
            handleLabelList(label) {
                this.current = 1
                this.label = label
                this.getData()
            },
            handleLabelMag() {
                if (this.$store.state.rolesList.length === 0) return this.addModalShow = true
                this.labelMagModalShow = true
            },
            handleLabelMagCancel() {
                this.labelMagModalShow = false
            },
            handleCreateTmpl() {
                let params = {
                        state: 'add',
                        dist_bucket_token: this.bucket_token,
                        templateType: 'template',
                        spaceType: 'person',
                    }
                if (this.is_org) params.palyable = false
                const { href } = this.$router.resolve({
                    name: "templateDesign",
                    query: { ...params }
                });
                window.open(href, '_blank')
            },
            async handleReachBottom(done) {
                await this.getLabelList()
                done()
            },
            handleLoadingModalCancel() {
                this.loadingModalShow = false

                this.percent = 0
                this.switch = false
            },
            hideFailedModal() {
                this.failedModalShow = false
                this.handleLoadingModalCancel()
                this.block_list = []
            },
        },
        async mounted() {
            await this.getTemplateTypeList()
            this.getTypeList('all')
            this.getLabelList()
            // this.getData()
        }
    }
</script>
<style scoped lang="less">
.material-block {
    margin-bottom: 23px;
    height: 166px;
    .img-wrap {
        display: inline-block;
        position: relative;
        margin-right: 20px;
        height: 166px;
        width: 295px;
        background: #cfcfcf;
    }
    .info {
        display: inline-block;
        vertical-align: bottom;
        font-weight: 700;
    }
}
#person-spaces {
    .action-bar {
        .radio-group-button {
            margin-left: -15px;
            position: relative;
            &::after {
                content: '\200B';
                position: absolute;
                top: 50%;
                right: -10px;
                width: 1px;
                height: 15px;
                background: #b4b9bf;
                transform: translateY(-50%);
            }
        }
        .dropdown {
            position: relative;
            margin-left: 20px;
            .close {
                display: none;
            }
            .label-mag {
                cursor: pointer;
                padding: 7px 0 7px 4px;
                font-size: 14px;
            }
            &:hover {
                .close {
                    display: block;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
            }
        }
        .dropdown-label {
            .tooltip {
                display: block;
                width: 100%;
                .label {
                    display: block;
                    max-width: 179px;
                }
            }
        }
        .dropdown-type {
            margin-left: 20px;
        }
    }
}
</style>